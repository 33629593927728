import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Stack, Typography, TextField } from "@mui/material";
import {
  StyledButton,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import {
  tokenReadFunction,
  vestingReadFunction,
  vestingWriteFunction,
  getVestingSchedule
} from "../ConnectivityAssets/hooks";

import { AppContext } from "../utils";
import { formatUnits, parseUnits } from "viem";
import Loading from "../components/SmallComponents/loading";
import { vestingAddress } from "../ConnectivityAssets/environment";

function HeroSection() {
  const { account } = useContext(AppContext);
  const [totalTokens, setTotalTokens] = useState(0);
  const [unlockedTokens, setUnlockedTokens] = useState(0);
  const [remainingTokens, setRemainingTokens] = useState(0);
  // const [totalAmount, setTotalAmount] = useState(0);
  const [releasedAmount, setReleasedAmount] = useState(0);

  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  const fetchData = async () => {
    if (account) {
      try {
        setLoading(true);
const decimals = await tokenReadFunction('decimals');
const totalTokensData = await vestingReadFunction('totalTokens', [
account,
]);
const unlockedTokensData = await vestingReadFunction('unlockedTokens', [
account,
]);
const remainingTokensData = await vestingReadFunction(
'availableTokensForWithdrawal',
[account]
);

const vestingSchedule = await getVestingSchedule(account);
const formattedTotalTokens = parseFloat(formatUnits(totalTokensData.toString(), decimals));
const formattedUnlockedTokens = parseFloat(formatUnits(unlockedTokensData.toString(), decimals));
const formattedRemainingTokens = parseFloat(formatUnits(remainingTokensData.toString(), decimals));
const formattedReleaseTokens = parseFloat(formatUnits(vestingSchedule.releasedAmount.toString(), decimals));
console.log("the formatted ones are :",formattedTotalTokens);

setTotalTokens(formattedTotalTokens);
setUnlockedTokens(formattedUnlockedTokens);
setRemainingTokens(formattedRemainingTokens);
setReleasedAmount(formattedReleaseTokens);

setLoading(false);


      } catch (error) {
        setLoading(false);
        console.log(error);
        showAlert('Error fetching data from the contract');
      }
    }
  };
  
  useEffect(() => {
    if (account) {
      fetchData();

      const interval = setInterval(() => {
        fetchData();
      }, 420 * 60 * 1000); // 30 Days in milliseconds

      return () => clearInterval(interval);
    }
  }, [account]);

  const withdrawHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    if (!withdrawAmount || isNaN(withdrawAmount) || withdrawAmount <= 0) {
      return showAlert("Error! Please enter a valid amount to withdraw.");
    }
    try {
      setLoading(true);
const decimals = await tokenReadFunction('decimals');
const amountToWithdraw = parseUnits(withdrawAmount.toString(), decimals);

console.log("withdraw amount: ", amountToWithdraw);

const receipt = await vestingWriteFunction("withdraw", [amountToWithdraw]);
console.log(receipt);
setLoading(false);
showAlert("Success! Transaction Confirmed", "success");

     fetchData();

    } catch (error) {
      setLoading(false);
      showAlert(error?.shortMessage || "Transaction failed");
    }

  };

  return (
    <>
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Box>
        <Container maxWidth="sm">
          <Stack
            mt={4}
            sx={{
              background: "#0d1824",
              px: { xs: 2, sm: 4 },
              py: 2,
              borderRadius: "12px",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "#EBE9ED",
                fontSize: account ? "30px" : "15px",
                fontFamily: "Outfit",
                fontWeight: account ? "700" : "400",
                textAlign: "center",
              }}
            >
              {account
                ? "Your TUIT holdings"
                : "Connect your Metamask wallet to view your TUIT holdings"}
            </Typography>
            {account && (
              <>
                <Stack mt={2}>

                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#EBE9ED",
                      fontSize: "20px",
                      fontFamily: "Outfit",
                      fontWeight: "600",
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span>Total Allocated TUIT:</span>
                    <span>{parseFloat(totalTokens).toFixed(2)}</span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#EBE9ED",
                      fontSize: "20px",
                      fontFamily: "Outfit",
                      fontWeight: "600",
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span>Unlocked Amount:</span>
                    <span>{parseFloat(unlockedTokens).toFixed(2)}</span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#EBE9ED",
                      fontSize: "20px",
                      fontFamily: "Outfit",
                      fontWeight: "600",
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span>Withdrawn Till Date:</span>
                    <span>{parseFloat(releasedAmount).toFixed(2)}</span>
                  </Typography>


                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#EBE9ED",
                      fontSize: "20px",
                      fontFamily: "Outfit",
                      fontWeight: "600",
                       display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span>Available For Withdrawal:</span>
                    <span>{parseFloat(remainingTokens).toFixed(2)}</span>
                    {/* Available for Withdrawal: {parseFloat(remainingTokens).toFixed(2)} */}
                  </Typography>



                </Stack>
                <Box mt={2}
                 >

                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Amount to Withdraw"
                    value={withdrawAmount}
                    onChange={(e) => setWithdrawAmount(e.target.value)}
                    InputProps={{
                      style: { color: 'white' },
                       // Target the input field text
                    }}
                    InputLabelProps={{
                      style: { color: 'white' },
                      // Target the label text
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: 'white',
                      },
                    }}
                  />

                </Box>

                <Box mt={2}>
                  <StyledButton onClick={withdrawHandler} width="100%">
                    Withdraw TUIT
                  </StyledButton>
                </Box>
              </>
            )}
            <Box mt={1} />
            <a
              href={`https://etherscan.io/address/${vestingAddress}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#1F60C9",
                fontSize: "16px",
                fontFamily: "Outfit",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              View Smart Contract
            </a>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#EBE9ED",
                fontSize: "15px",
                fontFamily: "Outfit",
                fontWeight: "400",
                textAlign: "center",
                mt: 2,
              }}
            >
              Write to us at info@coinsforcollege.org for any queries about your vested tokens and release schedule.
            </Typography>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default HeroSection;
